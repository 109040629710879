body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.cardImg {
  float: left;
}

.cardimg-small {
    max-width: 200px
}


.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #ecf0f1;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.screenSizeAlert {
  display:none;
}

/* Ugly hack */
body.modal-open {
  overflow: visible !important;
}
/* Screensize adapted from Bootstrap */
@media (max-width: 767.98px) { 
  .screenSizeAlert {
    display: block;
  }

  .modal, .modal-open {
    overflow: hidden !important;
 }
}

.cardimg {
  max-width: 10rem;
}


.features {
  margin-left: 0;
  padding-left: 0;
}

@media (min-width: 576px) {
 .features-padding {
    margin-left: 4%;
  }
}

.well {
  text-align: center;
}

.well button {
  margin-left: 1%;
}